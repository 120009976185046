import React from 'react';
import { ThemeProvider } from '@mui/system';

import theme from './theme/theme';
import { Layout } from './components/partials';
import { CssBaseline } from '@mui/material';
import { BioPage } from './components/bio';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout>
        <BioPage />
      </Layout>
    </ThemeProvider>
  );
}
