import { Typography, Box, Link } from '@mui/material';
import React from 'react';

export default function Footer() {
  const today = new Date(Date.now());

  return (
    <Box sx={styles.root} component="footer">
      <Typography sx={styles.text} variant="subtitle2">
        © Harris Thomas {today.getFullYear()}
      </Typography>
      <Link sx={styles.link} href="https://www.zachmccoy.dev">
        Website by ZM Engineering
      </Link>
    </Box>
  )
};

const styles = {
  root: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    py: 1
  },
  text: {
    color: "text.secondary",
  },
  link: {
    fontSize: 10
  }
}
