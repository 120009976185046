import React from "react";
import Footer from "../Footer/Footer";
import { Box } from "@mui/material";

export default function Layout({ children }) {

  return (
    <main style={styles.root}>
      <Box sx={styles.content}>
        {children}
      </Box>
      <Footer />
    </main>
  );
};

const styles = {
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    flex: 1,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
};
