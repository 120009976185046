import React from "react";
import { Box, Typography, Link } from "@mui/material";
import logo from "../../../assets/Harris_Logo_grey.png";

export default function Profile(props) {
  return (
    <Box sx={[styles.root, props.style]}>
      <Box sx={styles.nameContainer}>
        <Box sx={styles.imgContainer}>
          <img src={logo} alt="HT Logo" />
        </Box>
        <Typography variant="h1" sx={styles.name}>
          Harris Thomas
        </Typography>
      </Box>
      <Link variant="h4" href="tel:336-848-4588">
        (336) 848-4588
      </Link>
    </Box>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    p: 2,
  },
  name: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  imgContainer: {
    width: {
      xs: "30%",
      sm: "60%",
    },
    "& img": {
      width: "100%",
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
