import { createTheme } from "@mui/material";

const theme = createTheme(
  {
    palette: {
      mode: "dark",
      primary: {
        main: '#555556',
      },
      secondary: {
        main: '#00bcd4',
      },
    },
    typography: {
      fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',
      fontSize: 10,
      h1: {
        fontWeight: 800,
        fontSize: '3.25rem'
      },
      h2: {
        fontWeight: 700
      },
      h3: {
        fontWeight: 600
      },
      h4: {
        fontWeight: 500
      },
    },
  }
);

export default theme;