import React from "react";
import {
  Card,
  Box,
  CardContent,
  Typography,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";

export default function JobCard({
  title,
  links = [],
  city,
  email,
  style,
  logo,
}) {
  return (
    <Card sx={[styles.root, style]}>
      <CardContent>
        {Boolean(logo) && (
          <Box sx={styles.imgContainer}>
            <img src={logo} alt={title} />
          </Box>
        )}
        <Typography sx={styles.title} variant="h5">
          {title}
        </Typography>
        <Typography sx={styles.city} variant="subtitle1">
          {city}
        </Typography>

        <List disablePadding sx={styles.list}>
          {links.map((el, i) => (
            <>
              <ListItem key={i} disablePadding dense>
                <ListItemButton href={el.url} target="_blank">
                  {Boolean(el.icon) && (
                    <ListItemIcon sx={styles.iconContainer}>
                      <el.icon />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={el.title || el.url} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>

        <Typography align="center">
          For {title} Inquiries please email:{" "}
          <Link sx={styles.email} href={"mailto:" + email}>
            {email}
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
}

const styles = {
  root: {
    bgcolor: "primary.dark",
    flex: 1,
    maxWidth: 400,
    "& .MuiCardContent-root": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  city: {
    color: "text.secondary",
  },
  email: {
    color: "white",
    fontWeight: "bold",
  },
  list: {
    my: 1,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiListItem-root": {
      "& .MuiListItemText-root": {
        overflow: "hidden",
      },
      "& .MuiListItemIcon-root": {
        display: "flex",
        justifyContent: "center",
      },
    },
  },
  imgContainer: {
    width: "100%",
    "& img": {
      width: "100%",
      borderRadius: 3,
    },
  },
  iconContainer: {},
};
